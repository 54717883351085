import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Endorsement from "../Endorsement";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2vh",
  },
  break: {
    flexBasis: "100%",
    height: "1vh",
  },
}));

const BigKids = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [bigKids, setBigKids] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setBigKids(
      props.endorsements
        .filter((endorsement) => {
          return (
            endorsement.title.includes("Director") ||
            endorsement.title === "Senior Instructor" ||
            endorsement.title === "Staff Instructor"
          );
        })
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (b.title > a.title) {
            return 1;
          }
          return 0;
        })
    );
    // return () => {
    //   cleanup
    // }
  }, [props.endorsements]);

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Senior and Staff Instructors
      </Typography>

      {bigKids.map((endorsement, index, array) => {
        return (
          <div key={index}>
            {index > 0 && array[index].title !== array[index - 1].title ? (
              <div className={classes.break}></div>
            ) : null}
            <Endorsement
              handleChange={handleChange}
              expanded={expanded}
              endorsement={endorsement}
              index={index}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BigKids;
