import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ToolTip from "@material-ui/core/Tooltip";
import GitHubIcon from "@material-ui/icons/GitHub";
import DescriptionIcon from "@material-ui/icons/Description";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Resume from "../../Assets/Resume Experience.pdf";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    // maxWidth: "100%",
    backgroundColor: theme.palette.primary.dark,
    position: "fixed",
    bottom: 0,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    // padding: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  rootShift: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      width: (props) => `calc(100vw - ${props.drawerWidth}px)`,
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  selected: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.tertiary.main,
    },
    fontSize: "3em",
    padding: 0,
  },
}));

const Footer = (props) => {
  const classes = useStyles(props);
  const [value, setValue] = useState("Resumé");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={clsx(classes.root, {
        [classes.rootShift]: props.open,
      })}
    >
      <ToolTip title="Resumé">
        <BottomNavigationAction
          className={classes.selected}
          label="Resumé"
          icon={<DescriptionIcon />}
          href={Resume}
          target="_blank"
        />
      </ToolTip>
      <ToolTip title="Email">
        <BottomNavigationAction
          className={classes.selected}
          label="Email"
          icon={<AlternateEmailIcon />}
          href="mailto: islegers@elevenfifty.org"
        />
      </ToolTip>
      <ToolTip title="LinkedIn">
        <BottomNavigationAction
          className={classes.selected}
          label="LinkedIn"
          target="_blank"
          href="https://www.linkedin.com/in/ingeborg-slegers/"
          icon={<LinkedInIcon />}
        />
      </ToolTip>
      <ToolTip title="GitHub">
        <BottomNavigationAction
          className={classes.selected}
          label="GitHub"
          icon={<GitHubIcon />}
          href="https://github.com/IngeborgSlegers"
          target="_blank"
        />
      </ToolTip>
    </BottomNavigation>
  );
};

export default Footer;
