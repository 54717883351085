import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Icons from "./Icons";
import Video from "./Video";
import AboutMe from "./AboutMe";
import Certification from "./Certification";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import "./Home.css";
import { Link } from "react-scroll";
import ClassData from "./ClassData";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: 0,
    padding: theme.spacing(0),
    // width: "98vw"
    display: "block",
    // scrollBehavior: "smooth"
  },
  icons: {
    width: "100%",
    display: "flex",
    justifyContent: "bottom",
    paddingBottom: "4em",
  },
  break: {
    flexBasis: "100%",
    height: "4em",
  },
  icon: {
    width: "4vw",
    margin: theme.spacing(6, "auto"),
    "&:hover": {
      width: "8vw",
      margin: theme.spacing(0, "auto"),
      overflow: "hidden",
      // padding: 0
    },
  },
  main: {
    display: "flex",
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexDirection: "column"
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: "row"
    },
    justifyContent: "space-around",
    // flexWrap: "wrap"
  },
  arrowIcon: {
    fontSize: "3em",
    color: theme.palette.secondary.main,
    width: "1.25em",
    height: "1.25em",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="jumbotron">
        <Typography variant="h1" className="title">
          Ingeborg Slegers
        </Typography>
        <div className="arrow bounce">
          <Link
            activeClass="active"
            to="mainPage"
            spy={true}
            smooth={true}
            // offset={-70}
            duration={500}
          >
            <ArrowDownwardIcon className={classes.arrowIcon} />
          </Link>
        </div>
      </div>
      <div className="mainPage">
        <div className={classes.break}></div>
        <ClassData />
        <div className={classes.main}>
           <Video /> 
           <AboutMe /> 
        </div>
        <div className={classes.break}></div> 
         <Certification /> 
        <div className={classes.break}></div> 
         <Icons /> 
         <div className={classes.break}></div>
      </div>
    </div>
  );
};

export default Home;
