import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import QuoteDisplayOne from "./QuoteDisplayOne";
// import QuoteDisplayTwo from './QuoteDisplayOne';
import TeachingImage from "../../../Assets/marvin-meyer-SYTO3xs06fU-unsplash.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
    marginBottom: "3vh"
  },
  title: {
    // flexGrow: 1,
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    alignContent: "center",
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
  header: {
    backgroundImage: `url(${TeachingImage})`,
    height: "30vh",
    width: "100%",
    // padding: theme.spacing(0),
    marginBottom: theme.spacing(6),
    display: "flex",
    backgroundSize: "cover",
    backgroundPosition: "center 25%",
  },
}));

const Teaching = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          Effective Teaching and Techniques
        </Typography>
      </div>
      {/* ​<div className={classes.break}></div> */}
      <div>
        <Typography className={classes.textBox}>
          <span className={classes.firstLetter}>O</span>ver the past two years
          of being a part of the Web Development Team, known as the JavaScript
          Team when I was first brought on board, I have been a part of a dozen
          different cohorts. Whether it is starting a class with Gold Badge and
          saying goodbye as I hand it off to another instructor, inheritating a
          new class at the start of a new badge, or seeing a cohort the whole
          journey through, my goal is to guide and prepare the students, to the
          best of my abilities, for their new careers no matter the time I
          interact with them. I have had the opportunity to observe and be under
          the tutelage of several instructors that have since been gainfully
          employed as developers (Alecx Moritz, Autumn Henderson, Tom McClellan,
          Tyler Shelton, Lawrence Wagner, and Ransford Walker) as well as other
          instructors on the Learning Team. I have had the ability to utilize
          and hone my teaching skills by means of teaching a singular badge,
          multiple badges, and/or an entire cohort through as well as different
          formats. Furthermore, I have been able to pass on much of what I have
          learned to the trainees I have mentored, the majority of which have
          been promoted to instructors.
        </Typography>
        <Typography className={classes.textBox}>
          <span className={classes.firstLetter}>F</span>rom a young age on, I
          have aspired to be a part of collective and collabortive environment
          where the focus is on the flow of information being shared and the
          feedback looping back. As I explored the world more, I assigned that
          role as <i>teacher</i>. However, observing my peers, I quickly
          discovered I did not want to become a teacher. The distinction is
          minute but it is there, I promise. I longed for the audience to be
          filled with those that were there for the same reasons I was: the
          enjoyment of the topic, the passion to learn, the motivation to
          discover, the curiosity to ask questions, and more.
        </Typography>
        <div className={classes.quoteDisplay}>
          <QuoteDisplayOne />
          {/* <QuoteDisplayTwo /> */}
        </div>
        <Typography className={classes.textBox}>
          <span className={classes.firstLetter}>L</span>ittle did I know that
          this passion would be fed and flamed when I was first hired on with
          Eleven Fifty Acadamy in the beginning of 2019. The format of the
          bootcamp, with its quick turnaround and opportunity to teach the same
          lesson often, forced me to adapt to each class, lean on my mentors for
          guidance, learn from my previous experiences in order to determine the
          correct approach with each interaction, and adapt each lesson to each
          class. But the most crucial thing I have learned from my time as an
          instructor is this: "How do I phrase my question?" I use this
          technique to help pose my questions in order to convey to the student
          exactly what information I want them to give me. I use this technique
          primarily when I am guiding a student to an answer, solution, or the
          pathway to either an answer or a solution, dependent on what stage the
          student is in the course. A question for a question. The phrasing is
          important because even when I know what the answer is to the student's
          question, I need to make sure the student knows what path they need to
          follow to get to that same solution. I ensure I use keywords in my
          question in order to spark the right memory and allow the student to
          find success on their own.
        </Typography>
        <Typography className={classes.textBox}>
          <span className={classes.firstLetter}>T</span>eaching has given me the
          opportunity to fall in love with questions, find the victories even
          in the darkest of corners, and has enabled me to celebrate each
          student in their own learning and discoveries.
        </Typography>
      </div>
    </div>
  );
};

export default Teaching;
