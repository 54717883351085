import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
  },
  title: {
    textDecoration: "underline",
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
}));

const BlueAndRedBadge = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h5" align="left" className={classes.title}>
        Blue and Red Badge
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>B</span>y far, the largest impact
        this year on the Web Development curriculum will be the splitting up of
        the content currently covered in Blue Badge: NodeJS with Express,
        PostgreSQL, and Sequelize for the server side and React for the client
        side of an application. Blue Badge will focus on React’s Class
        components and group projects. While we currently teach React, the
        switch from Hooks to Class Components would require us to convert and
        rewrite the bulk of our current teaching materials, including all of the
        modules that introduce React to the students found in Canvas. We will
        also refactor the three or four several hours long lessons (both the
        video and in class lessons), challenges, challenge solutions, and
        assignments. For the final project in Blue Badge, students will be given
        pre-built servers for which they will create clients in groups.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>I</span>n the updated Red Badge, students will
        create an individual based full stack application with the introduction
        of NodeJS, Express, PostgreSQL, and Sequelize. The division will allot
        more time in Blue and Red Badge for learning to take place and in
        addition, we will provide more modules than before to support this learning. We
        anticipate that, with the dividing of the content, students will have
        more opportunities to dive deeper into the concepts thereby having a
        stronger base knowledge which in turn creates stronger future
        developers. While we have many learning materials constructed, with the
        additional time for learning, we have identified new topics that will
        require the creation of new learning materials which may include written
        modules, whole or small group instruction lesson plans, challenges,
        and/or assignments including rubrics. For Red Badge, I assisted in
        identifying concepts to include the new Web Development curriculum,
        including introduction to SQL, SOLID Principles, as well as building
        towards an N-tier database architecture. This will require rewriting all
        of the materials we had been providing students in Blue Badge as well as
        creating additional content.
      </Typography>
    </div>
  );
};

export default BlueAndRedBadge;
