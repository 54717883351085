import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import BigKids from "./Filtered/BigKids";
import Juniors from "./Filtered/Juniors";
import Noobs from "./Filtered/Noobs";
import Endorsements from "./endorsements";
import AllEndorsements from "./Filtered/AllEndorsements";
import CardActionArea from "@material-ui/core/CardActionArea";
import Coworkers from "../../../Assets/EFAClassroom.jpg";
import CoffeeImage from "../../../Assets/nathan-dumlao-6VhPY27jdps-unsplash.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
    marginBottom: "3vh"
  },
  header: {
    backgroundImage: `url(${CoffeeImage})`,
    height: "30vh",
    width: "100%",
    marginBottom: theme.spacing(6),
    display: "flex",
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  title: {
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    alignContent: "center",
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    margin: "2vh auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: { 
      flexDirection: "row",
    },
  },
  card: {
    margin: "auto 2vw",
    [theme.breakpoints.down("md")]: {
      margin: "1vw 2vw",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      boxShadow:
        "inset 0 -3em 3em rgba(0,0,0,0.1), 0 0  0 2px rgb(255,255,255), 0.3em 0.3em 1em rgba(0,0,0,0.3)",
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "2em",
    },
    [theme.breakpoints.up("lg")]: { 
      padding: "4em 2em",
    },
  },
  buttonTitle: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25em",
    },
    [theme.breakpoints.between("md","1180")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("1181")]: { 
      fontSize: "1.25em" 
    },
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  imgCard: {
    width: "50%",
    margin: "3vh auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "80%",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "65%",
    },
  },
  media: {
    width: "100%",
    border: "3px solid #ddd",
    borderRadius: "4px",
    padding: "1em",
    objectFit: "contain",
  },
}));

const MainEndorsements = (props) => {
  const [endorsements] = useState(Endorsements);
  const classes = useStyles();
  let { path, url } = useRouteMatch();


  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          Endorsements
        </Typography>
      </div>
      <div>
        <div>
          <Typography className={classes.textBox}>
            <span className={classes.firstLetter}>B</span>elow is a collection
            of statements from a number of members of the Coding Team at Eleven
            Fifty Academy. These statements were gathered in hopes of
            demonstrating my connection with my team from the newest additions
            as well as the more senior instructors. Furthermore, this collection
            includes referrals from members of both the Web Development and the
            Software Development Team.
          </Typography>
        </div>
        <div className={classes.imgCard}>
          <img
            src={Coworkers}
            className={classes.media}
            alt="A dozen individuals sitting in a classroom."
          />
        </div>
        <div className={classes.cards}>
          <Card className={classes.card}>
            <Link to={`${url}/all`}>
              <CardActionArea className={classes.button}>
                <Typography variant="h6" className={classes.buttonTitle}>
                  All Endorsements
                </Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Card className={classes.card}>
            <Link to={`${url}/bigkids`}>
              <CardActionArea className={classes.button}>
                <Typography variant="h6" className={classes.buttonTitle}>
                  Senior And Staff Instructors
                </Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Card className={classes.card}>
            <Link to={`${url}/juniors`}>
              <CardActionArea className={classes.button}>
                <Typography variant="h6" className={classes.buttonTitle}>
                  Instructors and Trainees
                </Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Card className={classes.card}>
            <Link to={`${url}/learningassistants`}>
              <CardActionArea className={classes.button}>
                <Typography variant="h6" className={classes.buttonTitle}>
                  Learning Assistants
                </Typography>
              </CardActionArea>
            </Link>
          </Card>
        </div>
        <Switch>
          <Route exact path={`${path}/all`}>
            <AllEndorsements endorsements={endorsements} />
          </Route>
          <Route exact path={`${path}/bigkids`}>
            <BigKids endorsements={endorsements} />
          </Route>
          <Route path={`${path}/juniors`}>
            <Juniors endorsements={endorsements} />
          </Route>
          <Route path={`${path}/learningassistants`}>
            <Noobs endorsements={endorsements} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default MainEndorsements;
