import JS from "../../Assets/icons8-javascript.svg";
import CSS from "../../Assets/icons8-css3.svg";
import HTML from "../../Assets/icons8-html-5.svg";
import Angular from "../../Assets/icons8-angularjs.svg";
import MaterialUI from "../../Assets/icons8-material-ui.svg";
import Bootstrap from "../../Assets/icons8-bootstrap.svg";
import NodeJS from "../../Assets/icons8-node-js.svg";
import React from "../../Assets/icons8-react.svg";
import PostgreSQL from "../../Assets/icons8-postgresql.svg";
import TypeScript from "../../Assets/icons8-typescript.svg";
import VSCode from "../../Assets/icons8-visual-studio-logo.svg";
import Postman from "../../Assets/icons8-postman-api.svg";
import Git from "../../Assets/icons8-git.svg";
import Heroku from "../../Assets/icons8-heroku.svg";
import Trello from "../../Assets/icons8-trello.svg";

let iconArray = [
  { path: HTML, name: "HyperText Markup Language (HTML)" },
  { path: CSS, name: "Cascading StyleSheets (CSS)" },
  { path: JS, name: "JavaScript" },
  { path: TypeScript, name: "TypeScript" },
  { path: Bootstrap, name: "Bootstrap" },
  { path: MaterialUI, name: "Material-UI" },
  { path: NodeJS, name: "NodeJS" },
  { path: React, name: "React" },
  { path: Angular, name: "Angular" },
  { path: PostgreSQL, name: "PostgreSQL" },
  { path: VSCode, name: "VSCode" },
  { path: Postman, name: "Postman" },
  { path: Git, name: "Git" },
  { path: Heroku, name: "Heroku" },
  { path: Trello, name: "Trello" },
];

export default iconArray;
