import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
  },
  title: {
    textDecoration: "underline",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
}));

const General = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" align="left" className={classes.title}>
        General Curriculum Contributions
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>C</span>urriculum is never a job
        for an individual. A crucial element to developing a successful
        curriculum is the team of resources. First, the experts that help ensure
        the correct and important topics materials are taught as well consider
        the order and placement of the topics within the learning timetable.
        Next, we have a set of individuals that take the advice from experts and
        transform the data into a digestible format and break down the data into
        tasks. We also need a team to help prioritize specific tasks, coordinate
        with each other, and build the new materials. They ensure the new
        material has a cohesive voice and aligns with current teaching
        materials. The last element of the curriculum team are the instructors
        implementing the new materials in the classroom. Their feedback is
        crucial in verifying the impact of the new materials. Long story short,
        building curriculum requires a lot of internal and external
        communications which include meetings, notes, and data collection.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>W</span>e have access to external
        resources. These resouces provide us with oppportunities to collaborate,
        consult with, and learn from technological experts to aid in furthering
        our current curriculum. Sometimes this means we replace a fragment of
        the curriculum with a new technology or we update a topic or lesson with
        a newer version of the same material based on their suggestions. The
        student learning experience and the end result are always at the center
        of these conversations.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>O</span>ver the last two years, I
        have updated the syllabus for each badge of the Web Development Track
        and ensured access to the syllabi for the entire Learning Team to
        utilize across multiple formats. The Web Development section in Eleven
        Fifty Academy’s Catalogue has been updated to include the newly updated
        syllabus as well as a detailed course overview separating the specific
        concepts taught each week. I continuously refactor the rubrics for the
        Web Development Track to ensure more efficient grading as well as
        clearer project guidelines for the students. I helped lay the foundation
        and continue to contribute to the Template Canvas Courses for all
        formats.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>S</span>ometimes my work provides
        collaboration between language courses. In one opportunity I
        collaborated with Joshua Tucker, one of the Senior Instructors and
        currently the interim Director of Software Development, on rewriting the
        Statement of Work for the current Red Badge capstone projects. The
        Statement of Work lays out the expectations of the project,
        technologies, and project stages. This document assists in emulating an
        environment the students will encounter post-graduation. This is a
        crucial part of their learning experience while at Eleven Fifty Academy.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>I</span>n addition, I am assisting
        with the transfer of all the teaching materials used during the entire
        course. They are currently stored in a GitHub repository which
        instructors clone and open in Visual Studio Code to teach from. We are
        switching to GitBooks, a modern documentation platform, for better
        legibility and distribution among instructors on the Coding Team.
        Furthermore, this benefits the onboarding process for new team members,
        whether they are newly promoted trainees or new instructors.
      </Typography>
    </div>
  );
};

export default General;
