import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const [formerPath, setFormerPath] = useState("");
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState(pathname);

  const setPathFunction = () => {

    setFormerPath(currentPath)
    setCurrentPath(pathname)
  }

  useEffect(() => {
    setPathFunction()
  }, [pathname])

  useEffect(() => {
    if (!(currentPath.includes("endorsements") && formerPath.includes("endorsements"))){
      window.scrollTo(0, 0);
    }
    console.log(pathname)
  }, [currentPath]);

  return null;
}
