import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Endorsement from "../Endorsement";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2vh",
  },
}));

const AllEndorsements = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        All Endorsements
      </Typography>
      {props.endorsements
        .sort((a, b) => {
          if (a.name[0] < b.name[0]) {
            return -1;
          }
          if (b.name[0] > a.name[0]) {
            return 1;
          }
          return 0;
        })
        .map((endorsement, index) => {
          return (
            <Endorsement
              endorsement={endorsement}
              key={index}
              index={index}
              expanded={expanded}
              handleChange={handleChange}
            />
          );
        })}
    </div>
  );
};

export default AllEndorsements;
