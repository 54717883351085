import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Endorsement from "../Endorsement";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2vh",
  },
}));

const LearningAssistants = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Learning Assistants
      </Typography>
      {props.endorsements
        .filter((endorsement) => {
          return endorsement.title.includes("Learning Assistant");
        })
        .map((endorsement, index) => {
          return (
            <Endorsement
              handleChange={handleChange}
              expanded={expanded}
              endorsement={endorsement}
              key={index}
              index={index}
            />
          );
        })}
    </div>
  );
};

export default LearningAssistants;
