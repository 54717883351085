import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AppreciationImage from "../../Assets/gabrielle-henderson-M4lve6jR26E-unsplash.jpg";
// import AppreciationImage from '../../Assets/manuel-cosentino-M3fhZSBFoFQ-unsplash.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
    marginBottom: "3vh"
  },
  title: {
    // flexGrow: 1,
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    alignContent: "center",
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    marginBottom: "3em",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  textSection: {
    // paddingTop: "3vh",
    paddingBottom: "4vh",
  },
  header: {
    backgroundImage: `url(${AppreciationImage})`,
    height: "30vh",
    width: "100%",
    // padding: theme.spacing(0),
    marginBottom: theme.spacing(6),
    display: "flex",
    backgroundSize: "cover",
    backgroundPosition: "center 75%",
  },
}));

const Appreciation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          Appreciation
        </Typography>
      </div>
      <div>
        <div className={classes.textSection}>
          <Typography className={classes.textBox}>
            <span className={classes.firstLetter}>I</span> wanted to take the
            opportunity to thank some individuals. In writing this portfolio, I
            realized how much I have grown over the last two+ years. Notably,
            that growth did not happen without the help and support of a number
            of people at Eleven Fifty Academy.
          </Typography>
        </div>
        <div className={classes.textSection}>
          <Typography variant="h5">
            <i>Dr. Jonathan Huer</i> - Chief Learning Officer and{" "}
            <i>Mary Dougherty</i> - Director of Learning
          </Typography>
          <Typography className={classes.textBox} paragraph={true}>
            Jon and Mary, thank you for being my mentors. You are some of the
            individuals I have come to look to in moments of uncertainty and
            when in need of guidance. Thank you for always, or at least most of
            the time, trusting me, my intentions, and my actions.{" "}
          </Typography>

          <Typography variant="h5">
            <i>Adam Jayne</i> - Director of Web Development and{" "}
            <i>Joshua Tucker</i> - Director of Software Development
          </Typography>
          <Typography className={classes.textBox} paragraph={true}>
            Adam and Josh, I am not even sure where to start with thanking you.
            Both of you have been crucial in my growth as an instructor, mentor,
            and leader during my time at Eleven Fifty Academy. You have been my
            role models and mentors to come to for any questions or concerns
            whether it is related to a project, code, curriculum, class topics,
            students, etc. I could not have become the individual producing the
            caliper of work that I do today without your guidance. Thanks to
            you, I am learning the importance of my voice and the impact my
            ideas and heart have on my team. You have helped me channel my
            passion for my work and my students. And you are the first I come to to
            celebrate the equally important victories. Thank you from the depth
            of my heart for your time, energy, friendship, and guidance the last
            two years.
          </Typography>

          <Typography variant="h5">
            <i>Danielle Geiser</i> - Staff Instructor and <i>Amanda Knight</i> -
            Staff Instructor
          </Typography>
          <Typography className={classes.textBox} paragraph={true}>
            Danielle and Amanda, thank you for becoming some of my best friends.
            Your friendship means so incredibly much to me. Thank you for being
            the other half of my brain, for promoting my spicy-ness, and for
            supporting me finding my skills in becoming a leader on the team.
            Thank you for the brainstorm sessions and long curriculum or class
            related dilemmas and conversations. But more importantly, thank you
            for teaching me how to delegate and trust that I am not responsible
            for the completion of every single task.
          </Typography>

          <Typography variant="h5">
            <i>Taylor Dickens</i> - Trainee, <i>Jerome Flaherty</i> - Trainee,{" "}
            <i>Danielle Geiser</i> - Staff Instructor,{" "}
          </Typography>
          <div className={classes.break}></div>
          <Typography variant="h5">
            <i>Corynne Moody</i> - Trainee, <i>Donovan Triplett</i> -
            Instructor, and <i>Eric Winebrenner</i> - Instructor
          </Typography>
          <Typography className={classes.textBox} paragraph={true}>
            Finally, I would like to thank the trainees that I have had the
            pleasure to support and mentor. I have had the opportunity to see
            many of these trainees grow from students to the strong and capable
            instructors and instructors-to-be they currently are. You have
            helped me learn how to identify your strengths and weaknesses in
            order to support you in your growth. You have helped me hone several
            qualities that I feel a mentor needs to embody in order to best
            guide mentees: Being a good listener, Valuing diversity of
            perspectives, Knowledgeable, Nonjudgmental, Provide constructive
            feedback, Honest and candid, and Eager to learn to name a few.
            Thanks to you, I have been able to focus on building up those skills
            that need more attention in order to be a better advisor and
            supporter of you.
          </Typography>
        </div>
        <Typography variant="h5">
          But most importantly, I would like to thank the Academy!
        </Typography>
        <div className={classes.break}></div>
      </div>
    </div>
  );
};

export default Appreciation;
