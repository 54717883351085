import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Contribution from "../../../Assets/tim-mossholder-bo3SHP58C3g-unsplash.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
    marginBottom: "3vh"
  },
  title: {
    // flexGrow: 1,
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    alignContent: "center",
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  header: {
    backgroundImage: `url(${Contribution})`,
    height: "30vh",
    width: "100%",
    // padding: theme.spacing(0),
    marginBottom: theme.spacing(6),
    display: "flex",
    backgroundSize: "cover",
    backgroundPosition: "center 25%",
  },
}));

const Contributions = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          Contributions
        </Typography>
      </div>
      <div>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>I</span>n the late summer of
          2019, Eleven Fifty Academy opened their new headquarters in Downtown
          Indianapolis, while maintaining the existing campus in Fishers. This
          move effectively separated the Learning Team to two locations for the
          very first time. In order to maintain a cohesive team, I began to lead
          biweekly Web Development Team meetings via Zoom, a video conference
          platform. These became even more crucial with the announcement of
          working remotely for the foreseeable future in the wake of the global
          pandemic. These meetings usually include an agenda and each meeting’s
          recording is made available for any team member who was unable to
          attend. To further collaboration across various locations, I have
          created, organized, and shared curriculum and other necessary Web
          Development Team documentation via project planning boards and
          documents accessible via Google Drive, Sharepoint, and/or our Canvas
          LMS Instructional Resources Course.
        </Typography>
        ​<div className={classes.break}></div>
        <Typography variant="h4" align="left">
          Committees
        </Typography>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>S</span>ince having gone remote,
          I was nominated and accepted to be a member on two committees: Eleven
          Fifty Academy’s 2020 Holiday Party and Eleven Fifty Academy’s
          Strategic Change Committee. The 2020 Holiday Party was a hit! I was
          very grateful to be a part of this committee. My primary focus was to
          help create a safe environment for Eleven Fifty staff members to come
          together to celebrate a special time of year for many of us. My
          contribution came from my ability to navigate online interactions such
          as Zoom. The Learning Team successfully converted all of its classes
          to an online format over a weekend with the announcement of the impact
          of COVID-19 and has since conducted all of its classes via Zoom.
          Thanks to this exposure, I was able to support the committee and the
          event by providing my experience and knowledge of Zoom, its breakout
          rooms, its behaviours, as well as ideate and create formats and games
          that would work with the virtual environment. This included formatting
          and running the quiz portion of the Holiday Party.
        </Typography>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>T</span>he Strategic Change
          Committee came about in the beginning of 2021, after the introduction
          of Purdue’s Lean Six Sigma Program to Eleven Fifty Academy. Both the
          course and this committee were headed by Jennifer Sargeant, former
          Vice President of Continuous Improvement. The committee and its
          primary concepts of focus was formed based on the results collected
          from a company-wide survey. I, along with initially five other
          members, represent the Learning Team with myself as the team lead. Our
          focus for the first quarter was communication. Armed with this topic,
          each team would meet as well as communicate with the team they
          represented to identify low hanging fruit, action items that do not
          take a long time to implement but have an impact on quality of life at
          Eleven Fifty Academy. Our team devised two action items to better
          support communication for the Learning Team. The first is an internal
          Learning Team newsletter. This newsletter will help ensure that our
          incredibly large team has a better idea of everything going on within
          the team as well as provide a feedback loop to all members on the
          Learning Team. It will also bridge the communication between the
          specific languages within the Learning Team, creating a stronger team
          with a stronger knowledge base. The second action item we identified
          is weekly workshops for the Instructing Staff of the Learning Team.
          The topics for these workshops will vary from fundamentals each course
          teaches in order to cross train our staff, new computer or programming
          related concepts, and a variety of other topics. These workshops will
          be led by leading instructors as well as both internal and external
          experts.
        </Typography>
        ​<div className={classes.break}></div>
        <Typography variant="h4" align="left">
          Learning Team Support
        </Typography>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>T</span>he Learning Team is
          composed of many team members, each of who bring different levels of
          experience, talents, and abilities. Mary Dougherty, the Director of
          Learning, has been developing and rolling out a training process for
          the development of new instructors. With the expansion of the team,
          this has been a great addition to the Learning Team’s processes. We
          have different titles to identify an instructor’s classroom
          experience: Trainee, Instructor, Staff Instructor, and Senior
          Instructor. It is a requirement of those with more experience to train
          those who have recently transitioned to a new title. A Trainee is
          assigned an experienced instructor who will mentor and guide them in
          preparation of becoming an Instructor. With my knowledge and
          experience gained while at Eleven Fifty, I provide support to a large
          portion of the Web Development team.
        </Typography>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>I</span> advise instructors who
          are mentoring Trainees on how to best mentor, identifying their own
          strengths and weaknesses as well as the Trainee's. The advice I supply
          is applicable to the majority of our team members. However, I make
          sure to tailor my suggestions to both the instructor as well as the
          individual they are mentoring. I also support the instructors teaching
          the Part Time format. This usually entails making myself available
          outside of my regular working hours to accommodate their schedule due
          to the Part Time instructors’ availability. I ensure that any Web
          Development Team Meetings are recorded and the links of the recordings
          as well as any documents, resources, or notes shared during the
          meetings are accessible. When the curriculum changes, I provide
          materials and lessons so that the instructors are familiar with the
          new materials as well as update the Part Time Canvas Courses.
          Furthermore, I provide support to the Senior Instructors as well as
          the new Interim Directors of Web Development and Software Development.
          I give insight and advice to help brainstorm and devise solutions with
          Senior Instructors and the Interim Directors. After which, I aid in
          the breaking down, identifying, and delegatating or taking on the
          smaller action items. In addition, I also contribute my time and
          knowledge to the administration portion of the Learning Team to help
          update the content submissions to the Department of Workforce
          Development. These submissions reflect the new changes to the
          curriculum and rubrics.
        </Typography>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>M</span>y goal is to support the
          Web Development Team as well as the Learning Team as a whole to ensure our
          success, student success, and ultimately Eleven Fifty Academy’s
          success.
        </Typography>
      </div>
    </div>
  );
};

export default Contributions;
