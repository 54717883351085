import { createMuiTheme } from "@material-ui/core/styles";

const colortheme = createMuiTheme({
  palette: {
    primary: { 
      main: "#032136", 
      // dark: "#032136", 
      // light: "#b0c4de" 
    },
    secondary: { 
      light: "#032136", 
      main: "#b0c4de" 
    },
    tertiary: {
      main: "#fadadd"
    }
  },
  typography: {
    fontFamily: [
      'Libre Franklin', 
      'sans-serif'
    ].join(','),
  },
});


export default colortheme;
