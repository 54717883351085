import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from 'react'
import Endorsement from '../Endorsement';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: '33.33%',
    flexShrink: 0
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2vh",
  },
  break: {
    flexBasis: "100%",
    height: "1vh",
  },
}));

const Juniors = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [juniors, setJuniors] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setJuniors(props.endorsements.filter(endorsement => {
      return endorsement.title === 'Instructor' || endorsement.title === 'Trainee';
    }).sort((a, b) => {
      if (a.title < b.title) { return -1; }
      if (b.title > a.title) { return 1; }
      return 0;
    }))
    // return () => {
    //   cleanup
    // }
  }, [props.endorsements])

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Instructors and Trainees
      </Typography>
      {juniors.map((endorsement, index, array) => {
        return (index > 0 && array[index].title !== array[index - 1].title) ? <div key={index} ><div className={classes.break}></div> <Endorsement handleChange={handleChange} expanded={expanded} endorsement={endorsement} index={index} /></div> : <Endorsement handleChange={handleChange} expanded={expanded} endorsement={endorsement} key={index} index={index} />
      })}
    </div>
  );
};

export default Juniors;
