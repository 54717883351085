import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import FlareIcon from "@material-ui/icons/Flare";
import SchoolIcon from "@material-ui/icons/School";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import "./NavDrawer.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  linkstyle: {
    color: "inherit",
    "text-decoration": "none",
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      width: (props) => props.drawerWidth,
    },
  },
  drawerPaper: {
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      width: (props) => props.drawerWidth,
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
}));

const NavDrawer = (props) => {
  const classes = useStyles(props);
  const matches = useMediaQuery('(max-width:960px)');
  const theme = useTheme();

  return (
    <div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor={matches ? "top" : "left"}
        open={props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === "ltr" ? (
              (matches ? (<KeyboardArrowUpIcon/>) : (<ChevronLeftIcon />))
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/contributions">
            <ListItem button color="primary" onClick={matches ? props.handleDrawerClose : null}>
              <ListItemIcon>
                <PostAddIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.linkstyle}
                primary="Contributions"
              >
                Contributions
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/curriculum">
            <ListItem button color="primary" onClick={matches ? props.handleDrawerClose : null}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkstyle} primary="Curriculum">
                Curriculum
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/teaching">
            <ListItem button color="primary" onClick={matches ? props.handleDrawerClose : null}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText className={classes.linkstyle} primary="Teaching">
                Teaching
              </ListItemText>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to="/endorsements/all">
            <ListItem button color="primary" onClick={matches ? props.handleDrawerClose : null}>
              <ListItemIcon>
                <SpeakerNotesIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.linkstyle}
                primary="Endorsements"
              >
                Endorsements
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/appreciation">
            <ListItem button color="primary" onClick={matches ? props.handleDrawerClose : null}>
              <ListItemIcon>
                <FlareIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.linkstyle}
                primary="Appreciation"
              >
                Appreciation
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </div>
  );
};

export default NavDrawer;
