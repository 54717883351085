import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
      flexDirection: "column"
    },
    [theme.breakpoints.up('md')]:
    {justifyContent: "space-around",
    flexDirection: "row"}
  },
  textBox: {
    padding: theme.spacing(2)
  }
}));

const ClassData = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textBox}>
        <Typography variant="h4" paragraph={true}>160+</Typography>
        <Typography>Lives Impacted</Typography>
      </div>
      <div className={classes.textBox}>
        <Typography variant="h4" paragraph={true}>22+</Typography>
        <Typography>Months in the classroom</Typography>
      </div>
      <div className={classes.textBox}>
        <Typography variant="h4" paragraph={true}>12+</Typography>
        <Typography>Cohorts interacted with</Typography>
      </div>
    </div>
  );
};

export default ClassData;
