const endorsements = [
  {
    name: "Jayne, Adam",
    language: "Web Development",
    title: "Senior Instructor",
    quote:
      "As a Senior instructor, I cannot recommend highly enough for Ingeborg Slegers to join the guild of Seniors at Eleven Fifty Academy. She is an incredible instructor, teammate, and mentor. Her ability to create synergy in a classroom is only rivaled by skill at rallying the Web Development team to accomplish curriculum changes, even when everything seems like it is on fire. Furthermore, she grows Learning Assistants into great Instructors, consistently improving the quality of candidates that graduate our program. I cannot wait to see her contributions and administration of the Web Development team as a Senior Instructor. Resistance is futile.",
  },
  {
    name: "Tucker, Joshua",
    language: "Software Development",
    title: "Senior Instructor",
    quote:
      "I first met Ingeborg when she came on to the company as a Learning Assistant back in late 2018. Since then, I’ve been able to witness all of the incredible growth she has gone through first hand. While she will tell you that she has learned a lot from the team, I can tell you that the team has definitely learned a lot from her. Having the experience and exposure to the program from every rank other than Senior so far allows her to appreciate the perspectives of everyone, from student to experienced instructor. She brings all of her experience to the table in every conversation she’s a part of and is constantly striving to make Eleven Fifty Academy the best it can ever be. Ingeborg is constantly leading initiatives, even outside of her direct team. For Ingeborg, it’s never “me” or “my”, it’s always “us” and “our.” \rHer constant desire to improve and support has enabled many of us on the Learning Team (and others) to accomplish our goals. If an idea is brought up that seems like it will benefit the students or the program, you can be sure Ingeborg will be right there to help ensure its completion. This often plays a direct role in the future of EFA, especially when it comes to the training of the next generation of instructors. Ingeborg is an amazing mentor, leader, and friend. She listens to both staff and students and makes you feel heard, while still guiding you to be better. Pair this with her quality of work and determination, and you have one valuable team member.\n So if the question is “Should we promote Ingeborg Slegers to Senior Instructor?” then the answer is most assuredly, 100%, yes.",
  },
  {
    name: "Maynard, Zach",
    language: "Web Development",
    title: "Staff Instructor",
    quote:
      "I am writing on behalf of Ingeborg Slegers regarding the position of Senior Instructor. It is my privilege to recommend Ingeborg for the role of Senior Instructor at Eleven Fifty Academy. The dictionary defines superlative as: of the highest kind, quality, or order; surpassing all else or others; supreme. I define it as Ingeborg Slegers. As an instructor, as a leader, and as a person; she is of the highest kind, quality, and order. Supreme. Ingeborg and I were both hired on at Eleven Fifty Academy at the beginning of 2019, and she has consistently exhibited the utmost teamwork, leadership, dependability, and adaptability during our time together as co-workers. I can think of no one more deserving of the title of Senior Instructor. If you require more information or specific examples, please contact me at zmaynard@elevenfifty.org. This recommendation letter only provides a brief snapshot of Ingeborg’s aptitude and accomplishments as an employee of Eleven Fifty Academy. I would be happy to elaborate further on my time as a co-worker of Ingeborg’s. ",
  },
  {
    name: "Ahmann, Justin",
    language: "Web Development",
    title: "Instructor",
    quote:
      "Being here for a while now, I have had the privilege of watching Ingeborg grow from her first days of teaching to tackling classes of all sizes and personalities. Now as she looks toward becoming a senior staff instructor I hope that she will be able to share all that she has learned and experienced with our newer instructors. She is engaged both in the class and the course content. This would be an exciting next step for both her and efa; as she has shown time and time again that she can rise to any challenge.",
  },
  {
    name: "Drumm, Eli T.",
    language: "Web Development",
    title: "Instructor",
    quote:
      "I first encountered Ingeborg “Ing” Slegers as my instructor when I went through EFA's web dev program. Her passion for quality education and individuals was evident immediately. Over the time I've known her, she's developed a singular teaching style all her own that establishes rapport with students, elicits the best work from them, and is mindful of what they need to succeed in both situations in which they agree with her and in which they do not. She always, always has what's best for the student at the forefront of her mind, and I continue to strive to emulate her in my own teaching. Situations arise frequently and inevitably in our line of work that call for uncomfortable conversations and action, and she never fails to deal with these adeptly and with grace. Now, I am glancing at the Heuristics Document™, which says that a senior instructor needs to have mastery in four of five given areas. I can't speak to all of these, I'm sure she would know better than I about certain projects and her contributions to curriculum, and y’all would know about placement rates, so I'll focus on what I do know, which is that her teaching is immensely effective and she pursues curriculum development with intensity and intelligence. I have had the good fortune to learn under and work with Ing, so I know what I'm talking about when I say with no reservations that she is Senior Instructor material from my standpoint(s). I am of course available for any follow-up questions or clarifications you may require, but I hope I have made myself clear enough.",
  },
  {
    name: "Geiser, Danielle",
    language: "Web Development",
    title: "Staff Instructor",
    quote:
      "I gladly write this recommendation for Ingeborg Slegers who has been my teacher, mentor, and co-worker since I began learning, and then working at Eleven Fifty Academy. During my time as a student at Eleven Fifty it was an absolute pleasure to have Ingeborg as one of my instructors. Incredibly patient, caring, and reassuring her skills and personality lend her to be an excellent instructor. She can not only easily break down and explain complex material, but reassure students when they are facing some of the most stressful portions of the course. As my mentor when I first began as a learning assistant at Eleven Fifty Ingeborg encouraged me to continue to learn and explore and was always supportive. She helped guide me down a path of continuous learning and improvement and gave me all the resources I would need to continue to grow. She has helped me face difficult students and situations and I always trust her to give well thought out guidance and advice. Although our roles have changed I am so fortunate to have Ingeborg as my co-worker and Eleven Fifty is incredibly fortunate to have such a passionate, driven, and hard working individual as a part of the Learning Team. Ingeborg has been a force of positive change in my life and I know that she is the same for many of the students and staff at the Academy as well. I give my complete support to her in striving to become a Senior Instructor knowing that she would be more than successful taking on the responsibility of the role.",
  },
  {
    name: "Knight, Amanda",
    language: "Software Development",
    title: "Staff Instructor",
    quote:
      "I have been able to watch Ingeborg in the classroom as well as work a few intro to codes with her. She has a great energy with the students, and tends to bring infectious enthusiasm to each topic. I really admire how she handles questions with students as well. It may seem a simple thing but it matters significantly. The tone and verbiage that she uses creates a safe place to ask questions. No one wants to ask questions if they are made to feel bad about it. Outside of the class she works extremely hard on curriculum to make the program the best that it can be. On top of all of that she treats her coworkers with kindness and respect. Ingeborg is an individual that I admire and an instructor that I am honoured to work with.",
  },
  {
    name: "Vanarsdall, Rob",
    language: "Web Development",
    title: "Instructor",
    quote:
      "As you know I come from a military background and look at her as if she is my platoon leader. I’ve been at EFA a little over a year and a half. Ingeborg is one of those people that no matter how busy she is in her own space - that she is willing to lend a hand to me and others. She encapsulates all values that are a part of EFA. I look at her for guidance in student issues, curriculum ideas, and how to improve lessons. Since being with EFA she has taken on extra responsibilities such as helping to update the canvas courses, converting full-time to fit the part-time schedule, and holding regular meetings keeping the web development team up to date. I truly feel that she doesn’t do this because she has to - she really wanted to make EFA better. True Story: The other week I was thinking of asking Mary to see if there is some sort of employee recognition because if there were, I would like to see her get recognized for the work that she has done to make our lives as instructors easier. I guess the ultimate way to recognize her and show that appreciation would be to see the position of Big Kid Senior Instructor at EFA be assigned to Ingeborg Slegers.",
  },
  {
    name: "Winebrenner, Eric",
    language: "Web Development",
    title: "Instructor",
    quote:
      "In my time as a student, LA, and trainee with Ingeborg, I have found that I am consistently learning, becoming inspired, and encouraged to become better in my own skillsets. I believe her drive to teach, to learn, and to build upon the foundations currently in place here at Eleven Fifty is a true asset to our team and will only be enhanced by what she has to offer. I have utilized many of the tools she has built from her classes into how I structure and organize my own because I believe they are greatly valuable. I feel these help not only guide our students but help the instructional team stay on point. Lastly, I want to point out the fact that she is always up to bat when another team member seems to need assistance. This, to me, is a quality most desired within an employee, a peer, and, most importantly, within leadership.",
  },
  {
    name: "Wolanin, Adam",
    language: "Software Development",
    title: "Instructor",
    quote:
      "Not really sure how to start this but Ing is super amazing. She is always there for you when you need someone to talk about work and things outside of work. Also she is very helpful when it comes to asking questions about students and how to handle certain situations. She always lends a helpful hand whenever and wherever she can and is always willing to make sure you feel as supported you can. She is also very good at helping you gather your thoughts whether that be with students or trying to get curriculum ideas down. I have always looked up to her as well as Josh and Adam J. whenever I need help or ideas with anything.",
  },
  {
    name: "Flaherty, Jerome",
    language: "Web Development",
    title: "Trainee",
    quote:
      "My experience working with Ingeborg has been one of positivity and growth. From learning in her class to working with her in front of one, she has always been very knowledgeable and insightful on guiding students to answers. We have not worked alongside each other in too many cohorts, but I feel like she has provided me with both good advice for enhancing my instruction and a solid role model for communicating with coworkers and students alike.",
  },
  {
    name: "Moody, Corynne",
    language: "Web Development",
    title: "Trainee",
    quote:
      "It is with great pleasure that I recommend Ingeborg Slegers for Senior Instructorwith Eleven Fifty Academy. Ingeborg and I have been working together for just over a year and a half in multiple different capacities and I consider her a mentor in my own coding process. I have thoroughly enjoyed working with Ingeborg, and her skills have been an incredibly valuable asset to the team. She is intelligent, creative, and pragmatic in her thinking and in practice. Along with her talent, Ingeborg has always been great to work with. She is a true problem solver and always fosters engaging conversations in and outside of the classroom. From her sheer determination and effort to help students succeed, to the sharp developer skills, Ingeborg would make a tremendous addition to any team.",
  },
  {
    name: "Line, Jason",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "Ingeborg has been instrumental in my development as a Learning Assistant. Her commitment to excellence and integrity is what makes her shine through as a strong leader and mentor. She has not only instructed me with the curriculum, but has shown me the skills necessary to be an effective instructor. She is the true embodiment of what it means to be a great educator.",
  },
  {
    name: "Loveday, Nav",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "I had the distinct pleasure of having Ingeborg Slegers as my instructor for my entire time as a student at EFA. I really appreciated her balance between having fun and getting required work done. The cadence of each lesson was generally on point, not too fast or too slow. She often checked in using slack polling or other methods. She is easy to understand and promotes comprehension of small details or broad concepts. I feel that she has a very strong understanding of the content and is not afraid to admit when she doesn’t know (but will eventually find the answer!). When I had a question or bug, she almost always shared an outside resource with her hint or answer. While these hits and extra reading were sometimes frustrating, not handing me the answer made me have to think on my own. A few minutes later I would often see that resource pop up in the slack channel so that all the students in my cohort could benefit from my query. My favorite thing about Ingeborg is the way she asks for questions. There is always a long pause after to ensure each student has ample time to think, unmute zoom, and begin speaking. In addition to being an experienced instructor, she works well with other instructional staff. During class, she worked with Donovan on his instructing skills and allowed Jerome the opportunity to teach small lessons where he was comfortable. She is generally focused and organized, which is apparent in our staff meetings I’ve attended as an LA. I truly believe that Ingeborg would make an exceptional Senior Instructor if afforded the opportunity.",
  },
  {
    name: "Matson, Cris",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "Ingeborg Slegers guided our enormous class through three badges of new material with an unfaltering positive attitude, and developed a rapport with the class that softened the especially hard lessons. I was continually awed by her patience and empathy in debugging beginner code. On my third day of class, she shared a personal anecdote about a roadblock she had hit when she was starting her coding education, and when I hit that roadblock I felt like I was on the right path instead of at an impasse. Ingeborg is a supportive, driven, and skilled instructor. She is an invaluable asset to any classroom, be it virtual or brick & mortar.",
  },
  {
    name: "Kanvinde-Ray, Amruta",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "I had Ingeborg as my instructor in the Web Development full time course. I was one of the few students to have her guidance for the entire course. She’s an amazing instructor and made sure our class understood the basics before moving on. She has been a very good listener and I have never hesitated to go to her in sticky situations. For example, I had a bit of a weird situation with my group project with my group not being on the same line and us having our own differences. I was overwhelmed with the situation and had a talk with Ing. She listened to my concerns and calmed me down. She made sure what the exact problem was and helped me through it successfully. I have been very thankful of her for that. As a colleague, I like how organized she is with respect to her tasks and I look up to her in managing them better. I have had the support from Ing always and I know she will make an extraordinary senior instructor.",
  },
  {
    name: "Kerekes, Summer",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "I had the privilege of having Ing as an instructor at Eleven Fifty Academy. It was my second course with EFA, so I’ve experienced other instructors and have worked with even more since then. Ing has a way of connecting with her students that keeps them focused and continually learning. She is relatable yet firm. She is careful not to wander too far off-topic, as students can be easily overwhelmed by too much non-essential information. I have found that just knowing how to code does not make a great instructor, but knowing how to relate to people first, so they see you as trustworthy and reliable is far more important. Ing cares that her students are comprehending the material. Instructors who care work to find creative ways to explain concepts more in depth. These are traits and qualities Ingeborg exhibits and practices in every exchange with her students.",
  },
  {
    name: "Quinones-Perez, Ro-Marie ",
    language: "Web Development",
    title: "Learning Assistant (Former)",
    quote:
      "At the time of writing this statement, I was a Learning Assistant (LA) at Eleven Fifty Academy and served as an LA in Ingeborg Slegers’ most recent class (October 2020 through January 2021). After working with her this last cohort, I would like to recommend Ingeborg for the role of Senior Instructor. Ingeborg’s vast knowledge and passion for teaching was apparent in all of her interactions. As an LA, I particularly appreciated that Ingeborg empowered us by training the LA’s in methods of effective problem solving, code debugging, and student interaction/communication. In addition, Ingeborg made it her mission to ensure that the students felt empowered in their learning. For instance, when Ingeborg helped students debug code, she helped the student understand the code issue and the cause of the application error(s). She accomplished this by continually engaging the student during the debugging process. Her interactive approach sets the students up for success, as they will be confident in their skills and their ability to research necessary matters. Again, I sincerely recommend Ingeborg for the Senior Instructor position. Ingeborg’s ability to motivate students to develop their own passion for coding makes her a valuable asset that should be embraced by Eleven Fifty Academy.",
  },
  {
    name: "Smith, Jesse",
    language: "Web Development",
    title: "Learning Assistant",
    quote:
      "I would like to take some time to go on the record and say that Ingeborg Slegers is an incredible instructor, who makes a caring yet firm leader for students and learning assistants alike. My experiences with Ingeborg come from both sides, as a student and an assistant. I first learned Web Development under her, and can honestly say, from a first-hand perspective, that her teaching style and caring approach were invaluable to my education at Eleven Fifty Academy. Not long afterwards, I started working at EFA as a Learning Assistant, and have had the honor of working with Ingeborg in several cohorts. She is naturally empathetic and truly cares about the success and development of every student that she instructs. She handles any form of conflict or friction calmly and with the utmost professionalism, and extends as much grace and room as possible for students who may be struggling to succeed in the course, while still maintaining the integrity and standards that EFA upholds to produce qualified and able developers. I can confidently say that Ingeborg is more than a fitting candidate for the role of Senior Instructor, and would successfully fulfill the expectations and responsibilities of the position with the care and dignity that we all know and respect her for.",
  },
  {
    name: "Triplett, Donovan",
    language: "Web Development",
    title: "Instructor",
    quote:
      "Ever since my employment with Eleven Fifty Academy, there was always one person I knew I could always count on for anything. That person is Ingeborg Sledgers. Ingeborg is one of my primary mentors at Eleven Fifty Academy and supports me during times of person struggles. Ingeborg has such a big heart and is always willing to put students' needs above her own. Working with her has inspired me countless times and made me a much better teacher. That is just one very small example of how she has impacted my growth. Over the years, I have had the pleasure of observing Ingeborg’s growth as an instructor and leader. She is always one of the first ones to take the work no one else will and puts in every ounce of effort. This mentality and work ethic deserves to be rewarded and give the inevitable inspiration she brings a wider reach.",
  },
  {
    name: "Whitaker, John",
    language: "Web Development",
    title: "Learning Assistant (Former)",
    quote:
      "Ingeborg was an outstanding instructor for my October 2019 JavaScript class.  She was great at adapting her teaching style and pace to each student.  She made us all feel like a family and fostered an environment of working and learning together.  It was evident every day how much she cared about us as people and our progress as students.  I loved taking the class with her!",
  },
  { name: "Dickens, Taylor", language: "Web Development", title: "Trainee", quote: "It is my pleasure to write a recommendation for Ingeborg Slegers. I first met Ingeborg when she lead ITC on a Saturday in January 2019. She was cool, collected, and instructed the workshop with ease. Her knowledge and ability to direct a classroom convinced me to eventually enroll at Eleven Fifty Academy. Once I had saved enough money, I attended Eleven Fifty in late January 2020. During my time as a student, the outbreak of COVID had shifted our classroom from in-person to remote. Ingeborg stepped in and assisted our class with our Blue Badge projects. For every obstacle I ran into, Ingeborg helped break down the material and guided me to a solution. As my mentor, Ingeborg has supported me in every way. She has given me the resources I need to explore and grow on my continuous path of learning. If I'm feeling stressed or overwhelmed, she checks in to see how best to help. She is one of the few instructors that respect my pronouns and corrects those who do not use them. I have been fortunate to have Ingeborg as an instructor, mentor, and ally on my journey at Eleven Fifty. I give my full support to her becoming a Senior Instructor knowing she is more than qualified and will excel in this position." },
  // { name: "", language: "", title: "", quote: "" },
  // { name: "", language: "", title: "", quote: "" },
];
export default endorsements;
