import { useState, useEffect } from "react";
import "./App.css";
import clsx from "clsx";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import colortheme from "./theme";
import { Switch, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/Navigation/Navbar";
import NavDrawer from "./Components/Navigation/NavDrawer";
import Endorsements from "./Components/Layout/Endorsements/MainEndorsements";
import Footer from "./Components/Navigation/Footer";
import Teaching from "./Components/Layout/Teaching/Teaching";
import Home from "./Components/Layout/Home/Home";
import Classroom from "./Components/Layout/Classroom";
import Appreciation from "./Components/Layout/Appreciation";
import Curriculum from "./Components/Layout/Curriculum/Curriculum";
import Contributions from "./Components/Layout/Contributions/Contributions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#032136",
      borderRadius: "10em"
      // outline: "2em solid slategrey",
    },
  },

  root: {
    textAlign: "center",
    height: "100vh",
    width: "100vw",
    maxWidth: "100%",
    fontFamily: "Libre Franklin, Roboto, sans-serif",
    scrollBehavior: "smooth",
  },
  flexContent: {
    // [theme.breakpoints.up("md")]: {
    //   justifyContent: "center",
    //   flexDirection: "row",
    // },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  homeContent: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: "0",
    // marginLeft: -drawerWidth,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    marginLeft: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth,
      marginTop: "4vh",
      // backgroundColor: "pink"
    },
    [theme.breakpoints.between("sm", "lg")]: {
      marginTop: "4vh",
      // backgroundColor: "lightblue"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "4vh",
      // backgroundColor: "lightgreen"
    },
  },
  contentShift: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0,
      // backgroundColor: "red"
    },
    [theme.breakpoints.between("sm", "lg")]: {
      marginLeft: 0,
      // backgroundColor: "blue"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      // backgroundColor: "green"
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
  },
  homeHeader: {
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(6),
    //necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6, 2),
    },
  },
  footer: {
    padding: 0,
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const matches = useMediaQuery("small");

  useEffect(() => {}, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={colortheme}>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.flexContent}>
          <div>
            <Navbar
              handleDrawerOpen={handleDrawerOpen}
              drawerWidth={drawerWidth}
              open={open}
            />
            <NavDrawer
              handleDrawerClose={handleDrawerClose}
              open={open}
              drawerWidth={drawerWidth}
            />
          </div>
          <div
            className={clsx(
              // location.pathname === "/" ? classes.homeContent : classes.content,
              classes.content,
              {
                [classes.contentShift]: open,
              }
            )}
          >
            <div
              className={
                location.pathname === "/"
                  ? classes.homeHeader
                  : classes.drawerHeader
              }
            >
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/endorsements">
                  <Endorsements />
                </Route>
                <Route path="/teaching">
                  <Teaching />
                </Route>
                <Route path="/classroom">
                  <Classroom />
                </Route>
                <Route path="/curriculum">
                  <Curriculum />
                </Route>
                <Route path="/contributions">
                  <Contributions />
                </Route>
                <Route path="/appreciation">
                  <Appreciation />
                </Route>
              </Switch>
            </div>
            <div className={classes.footer}>
              <Footer drawerWidth={drawerWidth} open={open} />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
