import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
// import Image from 'material-ui-image';
// import TestTech from './TestTech';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    fontFamily: "Libre Franklin, Roboto, sans-serif",
    margin: "auto 3vw",
  },
  title: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "4vh auto",
  },
  break: {
    flexBasis: "100%",
    height: "2em",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: '1.1'
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: '1.7'
  },
  data: {
    padding: theme.spacing(6, 12),
    margin: theme.spacing(6, 6),
  },
  subTitle: {
    marginBottom: theme.spacing(4),
  },
  smallLeft: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.main,
    padding: "0.5em",
  },
  smallRight: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.primary.main,
    padding: "0.5em",
  },
  bigLeft: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.main,
    padding: "1.3em",
  },
  bigRight: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.primary.main,
    padding: "1.3em",
  },
  colorBlock: {
    display: "flex",
    justifyContent: "flex-start",
  },
  reverseBlock: {
    display: "flex",
    justifyContent: "flex-end",
  },
  pinkOutline: {
    borderColor: theme.palette.tertiary.main,
    borderStyle: "outset",
    padding: theme.spacing(4),
    borderWidth: theme.spacing(1)
  },
  blueOutline: {
    borderColor: theme.palette.primary.main,
    borderStyle: "outset",
    borderWidth: theme.spacing(1),
    margin: theme.spacing(6, 6),
  },
}));

const Classroom = () => {
  const classes = useStyles();
  const [toggleBox, setToggleBox] = useState(0);

  const toggleHover = (event) => {
    setToggleBox(parseInt(event.target.attributes[1].value));
  };
  const toggleUnHover = () => {
    setToggleBox(0);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" align="center" className={classes.title}>
        Classroom
      </Typography>
      {/* <TestTech /> */}
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>O</span>ver the past two years of being on the JavaScript/Web Development Team,
          I have been a part of close to a dozen different cohorts. Whether it is
          starting a class with Gold Badge and saying goodbye as I had it off to
          another instructor, inheritating a new class at the start of a new
          badge, or seeing a cohort the whole journey through, my goal is to guide
          and prepare the students, to the best of my abilities, for their new
          careers no matter the time I interact with them. I have had the
          opportunity to observe and be under the tutelage of several instructors
          that have since been gainfully employed as developers (Alecx Moritz,
          Autumn Henderson, Tom McClellan, and Tyler Shelton) as well as other
          instructors on the Learning Team. I have had the ability to utilize and
          hone my teaching skills by means of teaching a singular badge, multiple
          badges, and/or an entire cohort through as well as different formats.
          Furthermore, I have been able to pass on much of what I have learned to
          the trainees I have mentored, the majority of which have been promoted
          to instructors.
      </Typography>
      <div className={classes.break}></div>
      {/* 
      1. Jan2019 - ?? 20~
      2. March2019 - 12
      3. May2019 - ?? 20~
      4. June2019 - 7
      5. August2019 - 17
      6. October2019 - 9
      7. December2019 - 6
      8. March2020 - 9
      9. May2020 - 15
      10. July2020 - 20
      11. October2020 - 19
       */}
      <Divider />
      <div className={classes.break}></div>
      <div className={classes.subTitle}>
        <Typography variant="h3">Classroom Data</Typography>
        <div className={classes.break}></div>
        <Typography>(Hover over the boxes for more info)</Typography>
      </div>

      <div>
        <div className={classes.blueOutline}>
          <div className={classes.data}>
            <div className={classes.pinkOutline}>
              <div className={classes.colorBlock}>
                <Typography
                  onMouseEnter={(e) => toggleHover(e)}
                  onMouseLeave={toggleUnHover}
                  value={1}
                  variant="h2"
                  className={classes.smallLeft}
                >
                  12
                </Typography>
                {toggleBox === 1 ? (
                  <Typography variant="h4" className={classes.bigRight}>
                    Cohorts interacted with
                  </Typography>
                ) : null}
              </div>
              <div className={classes.break}></div>
              <div className={classes.reverseBlock}>
                {toggleBox === 2 ? (
                  <Typography variant="h4" className={classes.bigLeft}>
                    Lives impacted
                  </Typography>
                ) : null}
                <Typography
                  value={2}
                  variant="h2"
                  className={classes.smallRight}
                  onMouseEnter={(e) => toggleHover(e)}
                  onMouseLeave={toggleUnHover}
                >
                  160+
                </Typography>
              </div>
              <div className={classes.break}></div>
              <div className={classes.colorBlock}>
                <Typography
                  onMouseEnter={(e) => toggleHover(e)}
                  onMouseLeave={toggleUnHover}
                  value={3}
                  variant="h2"
                  className={classes.smallLeft}
                >
                  22
                </Typography>
                {toggleBox === 3 ? (
                  <Typography variant="h4" className={classes.bigRight}>
                    Months in the classroom
                  </Typography>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.break}></div>
      <Divider />
      <div className={classes.break}></div>
      {/* <TestTech /> */}
    </div>
  );
};

export default Classroom;
