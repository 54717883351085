import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    margin: "auto",
    padding: "1em",
  },
  font: {
    fontSize: "1.2em",
    lineHeight: "2",
  },
});

const AboutMe = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" paragraph={true}>
        Hi, my name is Ingeborg Slegers!
      </Typography>
      <Typography variant="body1" paragraph={true} className={classes.font}>
        I was born and raised in Belgium, a tri-lingual country. I moved to
        Indiana for further education and studied{" "}
        <u>
          <a
            href="http://theconversation.com/explainer-what-is-experience-design-20011"
            target="_blank"
            rel="noreferrer"
          >
            Experience Design
          </a>
        </u>{" "}
        and Archaeology, followed by a Master's Degree in Strategic Leadership
        and Organizational Design. As a result of my exposure to and affinity
        for learning multiple languages, along with a passion for creativity and
        logic, I fell in love with coding, creative problem solving, and
        development as a whole.
      </Typography>

      <Typography variant="body1" paragraph={true} className={classes.font}>
        I am incredibly passionate about creating ways to help others through
        app creation and website design to emphasize the user experience. My
        work is data-driven, innovative, and grounded in mindfulness. When
        building applications, I look for projects that prioritize information
        sharing and strengthening of my community.
      </Typography>

      <Typography variant="body1" paragraph={true} className={classes.font}>
        My high school education, which focused on the implementation of the{" "}
        <u>
          <a
            href="https://www.waldorfeducation.org/waldorf-education/rudolf-steiner-the-history-of-waldorf-education"
            target="_blank"
            rel="noreferrer"
          >
            Steiner Pedagogy
          </a>
        </u>{" "}
        , taught me to learn with my head, understand with my heart, and apply
        with my hands. I approach coding with this philosophy and strongly
        attempt to embody it in my everyday life.
      </Typography>
    </div>
  );
};

export default AboutMe;
