import { Card, CardContent, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  video: {
    [theme.breakpoints.down("md")]: {
      // width: "100vw",
      // margin: "2em",
      height: "40vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "50vw",
      height: "35.25em",
    },
  },
}));

const Video = () => {
  const classes = useStyles();

  return (
    <div>
      <Card>
        <CardContent>
          <CardMedia
            className={classes.video}
            component="iframe"
            src="https://player.vimeo.com/video/531521006" allow="fullscreen" allowFullScreen framborder="0"
            // <iframe src="https://player.vimeo.com/video/531521006" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default Video;
