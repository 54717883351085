import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "4vh auto",
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
}));

const Curriculum2019To2020 = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" align="left">
        Curriculum Contributions 2019 and 2020
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>F</span>rom the Instructor
        Workshop, we decided to move away from Angular and instead focus more on
        React. This decision was made in order for students to build a stronger
        knowledge of one library rather than have lesser understanding of a
        library as well as a framework. The lesson structure for 2020 reflected
        this decision. By the end of January of that year, the first cohort had
        transitioned to a group project building out a full stack application
        where the client side was built using React’s Class Components and
        TypeScript. Prior to 2020, the Web Development Course, originally the
        JavaScript Course, was structured with 3 weeks covering fundamentals, 5
        weeks introducing and implementing a library, and concluding the course
        with 4 weeks building a full stack application as a group with a new
        framework. We decided to switch to a new format where each badge would
        have 4 weeks. On the Learning Team, this is commonly referred to as the
        4-4-4 format. In June, we rolled out the newly structured course: 4
        weeks for the fundamentals, 4 weeks of a full stack application as a
        group with React Hooks, and 4 weeks applying 8 weeks of learning in a
        full stack application passion project with React Class Components. I
        identified new key concepts, lessons, challenges, and project
        requirements to update the curriculum to transition to the new 4-4-4
        schedule. My contributions also include updating or creating new lessons
        and learning materials to better convey information to the students and
        increase student understanding of specific concepts resulting in
        stronger projects and better prepared students. The lesson on DOM
        Manipulation I created has been transformed into videos to be included
        in the Part Time Format and I have created all new modules that reflect the
        build of a new project in our Canvas LMS utilizing NodeJS.
      </Typography>
    </div>
  );
};

export default Curriculum2019To2020;
