import iconsArray from "../codeIcons";
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "2em",
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexWrap: "wrap"
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: "nowrap"
    },
  },
  title: {
    justifyContent: "center",
    alignContent: "center",
    margin: "auto",
    background: `-webkit-linear-gradient(left, #000c1c 25%, ${theme.palette.secondary.main} )`,
    "-webkit-background-clip": "text",
    color: "transparent",
    // textShadow: `.1em .1em .20em #F0F8FF`,
    "*:after": {
      textShadow: `10px 10px 20px rgba(0, 0, 0, 0.75)`,
    },
  },
  icons: {
    display: "flex",
    // justifyContent: "bottom",
    paddingBottom: "4em",
    [theme.breakpoints.down('md')]: {
      width: "33%",
    },
    [theme.breakpoints.up('md')]: {
      width: "100%",
    },
  },
  break: {
    flexBasis: "100%",
    height: "4em",
  },
  icon: {
    width: "4vw",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      width: "50%"
    },
    margin: theme.spacing(6, "auto"),
    "&:hover": {
      width: "8vw",
      margin: theme.spacing(0, "auto"),
      overflow: "hidden",
      // padding: 0
    },
    transition: ".25s",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const Icons = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [techName, setTechName] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setTechName(event.target.id)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTechName(null)
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      {iconsArray.map((icon, index) => (
        // console.log(icon),
        <div className={classes.icons} key={index}>
          <img
            src={icon.path}
            alt={techName}
            className={classes.icon}
            // aria-owns={open ? "mouse-over-popover" : undefined}
            // aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            value={icon.name}
            id={icon.name}
          />
        </div>
      ))}
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            disableScrollLock
          >
            <Typography>{techName}</Typography>
          </Popover>
    </div>
  );
};

export default Icons;
