import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Curriculum2019To2020 from "./Curriculum2019To2020";
import Curriculum2021 from "./Curriculum2021/Curriculum2021";
import TeachingImage from "../../../Assets/altumcode-dC6Pb2JdAqs-unsplash.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
    marginBottom: "3vh"
  },
  title: {
    // flexGrow: 1,
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    alignContent: "center", 
    color: "white",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    [theme.breakpoints.down("md")]: {
      fontSize: "3em",
    },
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
  header: {
    backgroundImage: `url(${TeachingImage})`,
    height: '30vh',
    width: '100%',
    // padding: theme.spacing(0),
    marginBottom: theme.spacing(6),
    display: "flex",
    backgroundSize: 'cover',
    backgroundPosition: 'center 25%'
  }
}));

const Curriculum = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2" align="center" className={classes.title}>
          Curriculum Development
        </Typography>
      </div>
      <div>
        <Typography className={classes.textBox} paragraph={true}>
          <span className={classes.firstLetter}>I</span> have had the
          opportunity the last two years to attend workshops initially created
          by Dr. Jonathan Huer for the Learning Team to learn about technologies
          used in the Indianpolis area dev world from experts.
          The instructors took this information and set about updating the
          curriculum. Sometimes, this includes building new lessons and
          assignments, but most times this means rebuilding a badge from the
          ground up. All of the content, materials, modules, lessons, and
          assignments covered in the Web Development and Software Development
          courses are built interally by Eleven Fifty Academy's instructors.
          When instructors are not in the classroom, we are busy building and
          creating new content for the curriculum as new updates for the
          materials we teach come out every six months. In addition to refining
          my teaching skills, I have made several contributions to the Web
          Development curriculum.
        </Typography>
        ​<div className={classes.break}></div>
        <Curriculum2019To2020 />
        ​<div className={classes.break}></div>
        <Curriculum2021 />
      </div>
    </div>
  );
};

export default Curriculum;
