import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  accordionSummary: {
    alignItems: "center"
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
    // margin: "auto",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      textAlign: "left",
      paddingLeft: "1.5em"
      // justifyContent: "flex-start"
    },
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2vh",
  },
  span: {
    display: "flex",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "36%",
    flexShrink: 0,
    textAlign: "justify",
  },
  quote: {
    textAlign: "justify",
    padding: "2em 5em",
    [theme.breakpoints.down("md")]: {
      padding: "2em",
    },
  },
  quoteText: {
    lineHeight: "1.7",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

const Endorsement = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Accordion
      className={classes.root}
      key={props.index}
      expanded={props.expanded === props.index}
      onChange={props.handleChange(props.index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        className={classes.accordionSummary}
      >
        <Typography className={classes.heading}>
          {props.endorsement.name}
        </Typography>
        {matches ? null : (
          <div>
            <Typography className={classes.span}>
              {props.endorsement.title}
            </Typography>
            <Typography className={classes.span}>
              {props.endorsement.language}
            </Typography>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.quote}>
        <Typography className={classes.quoteText}>
          {props.endorsement.quote}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Endorsement;
