import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Carousel from "react-material-ui-carousel";
import quotes from "./Quotes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    padding: theme.spacing(2, 0)
  },
}));

const QuoteDisplay = () => {
  const classes = useStyles();

  useEffect(() => {
    // console.log("this is the useEffect");
  }, []);

  return (
    <div className={classes.root}>
      <Carousel>
        {quotes.map((quote, index) => {
          return (
            <Card key={index}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {quote.quote}
                </Typography>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {quote.author}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Carousel>
    </div>
  );
};

export default QuoteDisplay;
