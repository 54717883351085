import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import GoldBadge from "./GoldBadge";
import BlueAndRedBadge from "./BlueAndRedBadge";
import General from "./General";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
  },
  title: {
    // flexGrow: 1,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "4vh auto",
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
}));

const Curriculum2021 = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" align="left">
        Curriculum Contributions 2021
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>D</span>espite the global pandemic
        and its impediment of gatherings, 2021's Instructor Workshop was
        thankfully only slightly different. On January 22, 2021, the instructors
        of both the Software Development and Web Development teams gathered on a
        video conference for a session of brainstorming and discussion. We
        identified areas that needed more support, strategies we would like to
        implement, internal policies to help generate, addressing student
        concerns, as well as team announcements. A list of tasks and subtasks
        were created based off the discussions and assigned for various team
        members to take up when they are not assigned to a class.
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>W</span>e are continuing the
        momentum of modifying the curriculum for 2021. We identified two key
        elements to modify for 2021: Refactoring Gold Badge and overhauling Blue
        Badge and Red Badge.
      </Typography>
      <div className={classes.break}></div>
      <GoldBadge />
      <div className={classes.break}></div>
      <BlueAndRedBadge />
      <div className={classes.break}></div>
      <General />
    </div>
  );
};

export default Curriculum2021;
