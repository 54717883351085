import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 3vw",
  },
  title: {
    textDecoration: "underline",
  },
  break: {
    flexBasis: "100%",
    height: "1em",
  },
  textBox: {
    marginTop: "2vh",
    textAlign: "justify",
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
  firstLetter: {
    fontSize: "35px",
    lineHeight: "1.1",
  },
  quoteDisplay: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
    minHeight: "6vh",
  },
}));

const GoldBadge = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h5" align="left" className={classes.title}>
        Gold Badge
      </Typography>
      <Typography className={classes.textBox} paragraph={true}>
        <span className={classes.firstLetter}>F</span>irst, we are currently
        updating and rewriting all of the teaching materials in Gold Badge. Gold
        Badge is the 4 weeks where students learn the fundamentals of
        JavaScript. We decided it was crucial to demonstrate the implementations
        of web applications earlier and more clearly and steer away from the
        start of the course being spent in the integrated development
        environment’s (IDE) console. While this is not my current focus, my
        contributions to Gold Badge include refactoring the lesson on Object
        Oriented Programming in JavaScript with Classes, creating a new lesson
        on TypeScript, and proofreading the build of the new lessons for
        students to build out with the in-class instruction.
      </Typography>
    </div>
  );
};

export default GoldBadge;
