const quotes = [
  {
    quote: "It is the supreme art of the teacher to awaken joy in creative expressions and knowledge.",
    author: "Albert Einstein"
  },
  {
    quote: "The whole art of teaching is only the art of awakening the natural curiosity of young minds for the purpose of satisfying it afterwards",
    author: "Anatole France"
  },
  {
    quote: "My heart is singing for joy this morning! A miracle has happened! The light of understanding has shone upon my little pupil's mind, and behold, all things are changed!",
    author: "Anne Sullivan"
  },
  {
    quote: "The job of an educator is to teach students to see vitality in themselves",
    author: "Joseph Campbell"
  },
  {
    quote: "Teaching is only demonstrating that it is possible. Learning is making it possible for yourself",
    author: "Paulo Coelho"
  },
  {
    quote: "Teachers have three loves: love of learning, love of learners, and the love of bringing the first two loves together.",
    author: "Scott Hayden"
  },
];

export default quotes;