import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "100vw",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    //   marginLeft: (props) => props.drawerWidth,
    //   transition: theme.transitions.create(["margin", "width"], {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // },
  },
  certIcon: { 
    fontSize: "7em", 
    margin: "auto 2em", 
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  certText: {
    width: "50vw",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      margin: "auto",
    },
  },
  certBullets: {
    letterSpacing: "0.15em",
    textAlign: "justify"
  },
}));

const Certification = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <VerifiedUserIcon className={classes.certIcon} />
      <div className={classes.certText}>
        <Typography variant="h2" paragraph={true}>
          CIW JavaScript Specialist
        </Typography>
        <Typography variant="h5" paragraph={true}>
          Individuals with this certificate demonstrate an understanding of:
        </Typography>
        <div className={classes.certBullets}>
          <Typography variant="body2" paragraph={true}>
            JavaScript language essentials, including program flow control, form
            validation, event handling, debugging scripts and cookie creation.
          </Typography>
          <Typography variant="body2" paragraph={true}>
            JavaScript code development, including writing programs and writing
            scripts for the JavaScript object model and the creating custom
            objects.
          </Typography>
          <Typography variant="body2" paragraph={true}>
            Web site implementation, including authoring and scripting, Web
            content creation, Web APIs, and JavaScript libraries and frameworks.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Certification;
